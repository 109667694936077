.portfolio {
  width: 100%;

  padding-left: 0;
  padding-right: 0;

  background-color: rgba(0, 0, 0, 0.7);
}
.title {
  height: 25vh;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  background-color: rgba(43, 39, 39, 0.9);
}

.card {
  /* height: 60vh; */
}

.title-text:after {
  display: block;
  height: 2px;
  background-color: #28b062;
  content: " ";
  margin: 0 auto;
  width: 100px;
  margin-top: 30px;
}

/* [1] The container */
.img-hover-zoom {
  overflow: hidden;
  /* [1.2] Hide the overflowing of child elements */
}

/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
  transition: transform 0.7s ease;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform: scale(1.15);
}
@media only screen and (max-width: 1200px) {
  .card {
    height: 100%;
  }
}
