.jumbo {
  background-color:black;
  height:100vh;

  background-image: url("/img/jumbo.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  background-attachment: fixed;

}

hr {
  border-top: 5px solid white;
  width:100%;
}