.logo {
  background-image: url("/img/logo.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  width: 10%;
  height: 5vh;
}

.bg-secondary-opacity {
  background-color: rgba(0, 0, 0, 0.2) !important
}