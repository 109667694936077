.side-bar {
  position: fixed;
  bottom: 0;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.25);
  writing-mode: vertical-rl;
  text-orientation: upright;
  color: white;
  height: 10vh;
}
.faStepBackward {
  height: 10vh;
  width: 5vh;
}
