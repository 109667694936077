.background {
  background-color: black;
  height: 100vh;

  background-image: url("/img/portfolioBg.jpg");
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  background-attachment: fixed;

  overflow: scroll;
}

.transparent-bg-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.project-name {
  font-family: Averir;
  font-weight: bold;
  font-size: 3rem;
}

.project-name:after {
  display: block;
  height: 2px;
  background-color: #28b062;
  content: " ";
  margin: 0 auto;
  /* width: 100px; */
  margin-top: 30px;
}

hr {
  border-top: 5px solid white;
  width: 100%;
}

.image-carousel-container {
  width: 75%;
}

@media only screen and (max-width: 400px) {
  .image-carousel-container {
    width: 90%;
  }
}